// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBeL0VXaYx1LkWTAjS82IEkv2wO0vTQR5Q",
  authDomain: "indbnew3.firebaseapp.com",
  databaseURL: "https://indbnew3-default-rtdb.firebaseio.com",
  projectId: "indbnew3",
  storageBucket: "indbnew3.appspot.com",
  messagingSenderId: "775254126973",
  appId: "1:775254126973:web:8b94e6cd8b99e95b821c14",
  measurementId: "G-P6GWSBCSRC"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };